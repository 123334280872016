$bgcolor: black;
$textcolor: white;
$font-html: Lato;
$fontsize: 15px;
$main-container-wdt: 800px;
$main-container-align: center;

body {
    background-color: $bgcolor;
    color: $textcolor;
    font-size: $fontsize;
    font-family: $font-html;
}

.main-container {
    width: $main-container-wdt;
    text-align:center!important;
    display:inline-block;
}

/* -- w3s W3 CSS framework -- */
@import url(https://www.w3schools.com/w3css/4/w3.css);
@import url(https://fonts.googleapis.com/css?family=Vollkorn);
@import url(https://fonts.googleapis.com/css2?family=Vollkorn&family=Vollkorn+SC);
@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Pacifico);

/* -- style 
html, body {
    font-family: "Lato";
}
h1 {
    font-family: "Vollkorn SC", serif;
}
h2, h3, h4, h5, h6 {
    font-family: "Vollkorn";
}
.main-wrapper {
    position: absolute;
    top: 30px;
    width: 100%;
    z-index: 0;
}
.hdr-left {
    position: absolute;
    left: 10%;
    top: 30px;
    width: 300px;
    z-index: 0;
}
.hdr-right {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 90%;
    z-index: -1;
}
.content-wrapper {
    position: relative;
    top: 400px;
    z-index: 0;
}

-- */